// Skills Icons
import firebaseIcon from "./images/firebase.svg"
import javaIcon from "./images/java.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import androidIcon from "./images/android.svg"
import flutterIcon from "./images/flutter.svg"

// Social Icon
import githubIcon from "./images/github.svg"
import linkedinIcon from "./images/linkedin.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Ashlee",
  headerTagline: [
    //Line 1 For Header
    "Mobile app engineer",
    //Line 2 For Header
    "Women in Tech",
    //Line 3 For Header
    "San francisco bay area",
  ],
  //   Header Paragraph
  headerParagraph:
    "Motivated software engineer specializing in creating clean, modular, easily maintainable source code",

  //Contact Email
  contactEmail: "ashleez1526@yahoo.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: "Blismo", //Project Title - Add Your Project Title Here
      para:
        "Closely collaborate with products and other software engineers to iterate and release reward and redemption mobile apps in IOS and Android, powered by Flutter and firebase cloud functions.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njl8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://www.blismo.com/",
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: "Weather forecast", //Project Title - Add Your Project Title Here
      para:
        "Display granular data on your favorite locations with the user friendly UI, utilizing the public weather forecast api", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1605153864431-a2795a1b2f95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTY3ODl8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/aky0419/Forcast_review",
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      title: "Gallery share", //Project Title - Add Your Project Title Here
      para:
        "A photo sharing app similar to Instagram that allows users to upload photos, leave comments, interact with photos, and chat with friends. Api and database powered by Firebase.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1504083898675-c896ecdae86e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjJ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/aky0419/MyInstagram",
    },
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      title: "Expensify", //Project Title - Add Your Project Title Here
      para:
        "Android app that manages monthly expenses powered by python flask api server with MySQL database", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/aky0419/spending",
    },
    {
      id: 5, //DO NOT CHANGE THIS (Please)😅
      title: "Tesla charging helper", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/aky0419/TeslaRemontChargeControl",
    },
    {
      id: 6, //DO NOT CHANGE THIS (Please)😅
      title: "Project Six", //Project Title - Add Your Project Title Here
      para:
        "To make charging management easier, this app is aware of the electric plan and only commands the car to charge during the cheapest hour and to stop charging on higher price rate period", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "",
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
    ,{
        id: 5,
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "With a strong passion on building impactful apps, I am currently working as a software engineer intern at Blismo where we are building a reward and redemption app that also supports take out services to help local businesses to create and digitize their own business systems. We use flutter as a framework to build apps for both ISO and Android with the MVC architecture, interact via Restful api to google cloud function to integrate data from third party APIs like stripe and yelp to our database. I am responsible for takeout follow and payment flow. Moreover, I worked on the Push notification system, remote A/B testing config system, analytic crashlytic service to provision services and track down bugs,  and application release with CI/CI platforms like codemagic. ",
  aboutParaTwo:
    "Prior to my software development interest, I had been contributing professionally to the biotechnology field for 6 years as a scientist with focus in hybridoma-based antibody discovery. Leading the team of 4, we screen, and identify the leading antibodies from thousands of candidates for the discovery of potential antibody therapeutics for various disease targets.",
  aboutParaThree:
    "One of my day-today responsibilities is to analyze the experiment data. I relied on excel to process and analyze data which could be time consuming and repetitious. Hence, I started getting into the programming field by using macros. In the beginning, I was solely recording and running the macros. However, with larger data sets and complex analyses, it becomes hard to achieve goals solely with excel. This is the first time I started developing an interest in computer science. I want to know more computer languages and algorithms to process more complex data. Even when I am working on slides with PowerPoint, I always wonder if automation could be implemented to generate the charts and tables in slides.",
  aboutParaFour: "In an effort to broaden my computer science knowledge, I enrolled in an introduction to computer science course on Udacity and learned key concepts using python as a programming language. I was so thrilled to complete the course and eager to dive-in more. Being a visual person, I want to build interactive apps and develop my interest in mobile development. I learned Java and android app development by following videos on YouTube, as well as taking courses on Udemy, and have built around 40 android apps using android studio, SQL database, google firebase, and AWS. I also built several of my own apps such as the Weather forecast app which displays weather utilizing public weather forecast API, a Spending app which manages monthly expenses and stores data in MySQL database, and Tesla charging helper which schedules charging at assigned times",
  aboutImage:
    "https://zyy-cdn.azureedge.net/public/1517343295438.jpeg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      id: 1,
      img: firebaseIcon,
      para:
        "Firebase",
    },
    {
      id: 2,
      img: javaIcon,
      para:"Java",
    },
    {
      id: 3,
      img: jsIcon,
      para:
        "Javascript, Nodejs",
    },
    {
      id: 4,
      img: reactIcon,
      para:
        "React",
    },
    {
      id: 5,
      img: androidIcon,
      para:
        "Android Development",
    },
    {
      id: 6,
      img: flutterIcon,
      para:
        "Flutter",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "",
  promotionPara:"", // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/aky0419" },
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/ashleezheng/",
    },
  ],

  // End Contact Section ---------------
}
